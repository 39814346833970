import { AuthContext } from 'context/Auth'
import { PreferencesContext } from 'context/Preferences'
import Layout from 'Layout'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

export default function Home() {
	const { isEmployee } = useContext(AuthContext)
	const { productRegion, setRegion } = useContext(PreferencesContext)
	const router = useRouter()

	function handleRegionSelection(route: string, region: 'Louisiana' | 'Standard' | 'Texas') {
		setRegion(region)
		router.push(route)
	}

	if (productRegion === 'Standard') router.push('/products/standard')
	if (productRegion === 'Louisiana') router.push('/products/louisiana')
	if (productRegion === 'Texas') router.push('/products/texas')
	else
		return (
			<Layout pageTitle="Getting Started" gray>
				<div className="mx-auto max-w-screen-lg px-4 py-20">
					<div className="text-center">
						<h1 className="text-4xl">What type of products are you looking for?</h1>
					</div>
					<div className="mt-6 flex justify-center gap-4">
						<div className="w-1/3">
							<button
								onClick={() => handleRegionSelection('/products/standard', 'Standard')}
								className="flex w-full flex-col items-center justify-center rounded border bg-white px-6 py-10 shadow hover:bg-slate-100"
							>
								<img src="/images/ccss-logo.png" alt="ccss logo" className="h-20" />
								<span className="mt-4 block text-center text-2xl">CCSS Products</span>
							</button>
						</div>
						<div className="w-1/3">
							<button
								onClick={() => handleRegionSelection('/products/louisiana', 'Louisiana')}
								className="flex w-full flex-col items-center justify-center rounded border bg-white px-6 py-10 shadow hover:bg-slate-100"
							>
								<img src="/images/ladoe-logo.png" alt="ccss logo" className="h-20" />
								<span className="mt-4 block text-center text-2xl">Louisiana Products</span>
							</button>
						</div>
						{process.env.NEXT_PUBLIC_ACTIVE_ENV === 'development' && (
							<div className="w-1/3">
								<button
									onClick={() => handleRegionSelection('/products/texas', 'Texas')}
									className="flex w-full flex-col items-center justify-center rounded border bg-white px-6 py-10 shadow hover:bg-slate-100"
								>
									<img src="/images/teks-logo.png" alt="ccss logo" className="h-20" />
									<span className="mt-4 block text-center text-2xl">TEKS Products</span>
								</button>
							</div>
						)}
					</div>
				</div>
			</Layout>
		)
}
